/* stylelint-disable color-function-notation */
// Custom Theming for Angular Material
@use '@angular/material' as mat;

// For more information: https://material.angular.io/guide/theming

// Plus imports for other components in your app.

// If any typography overrides are needed, put them here
// Material Typography ref: https://material.angular.io/guide/typography
$my-custom-typography-config: mat.define-typography-config();

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($my-custom-typography-config);

$rspndr-primary: (
  50: #e1f2f3,
  100: #b2dee1,
  200: #80cacd,
  300: #4cb4b8,
  400: #23a4a7,
  500: #009495,
  600: #008887,
  700: #007876,
  800: #006866,
  900: #004c48,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);
$rspndr-secondary: (
  50: #eaeffc,
  100: #cdd8e9,
  200: #b1bed2,
  300: #94a3bc,
  400: #7e8faa,
  500: #687c9a,
  600: #5a6d88,
  700: #495971,
  800: #3a475b,
  900: #273243,
  A100: #cdd8e9,
  A200: #5a6d88,
  A400: #3a475b,
  A700: #273243,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette($rspndr-primary, 300);

// Default is A200, A100, A400
/* stylelint-disable-next-line value-keyword-case */
$app-accent: mat.define-palette($rspndr-secondary, A400, A200, A700);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);
