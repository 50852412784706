/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 */

// Import Rspndr Material Theme
@import './theme/theme';
@import '~normalize.css/normalize.css';
@import '~font-awesome/css/font-awesome.min.css';
@import '~flag-icons/css/flag-icons.min.css';

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

// Custom Mat Table Styles
.mat-header-row {
  height: 48px !important;
  border-bottom: 2px solid var(--ion-color-light);
}

.mat-row {
  min-height: 40px !important;
}

.mat-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mat-row.activeRow {
  background-color: rgb(var(--ion-color-primary-rgb) / 50%);
}

.mat-row:not(.no-data-row):hover {
  background-color: var(--ion-color-light);
}

.mat-row.activeRow:hover {
  // blended color of primary and light
  background-color: #8fc9ce;
}

// custom mat-raised-button style
button.mat-raised-button {
  &.rspndr-button {
    border-radius: 999px;
    letter-spacing: 0.05em;
    padding: 0 24px;
  }
  &.uppercase {
    text-transform: uppercase;
  }
  &.small {
    line-height: 24px;
    padding: 0 16px;
  }
  &.mobile-small {
    @media (max-width: 900px) {
      line-height: 32px;
      padding: 0 12px;
    }
  }
}

.dark-backdrop {
  background-color: rgb(0 0 0 / 80%);
}

// custom colors for mat buttons and snackbars
.mat-warning {
  background-color: #e67e22;
  color: #fff;
}

.snackbar-warning {
  background-color: #e0c248;
  color: #000;
}

.mat-success,
.snackbar-success {
  background-color: #10dc60;
  color: #000;
}

.snackbar-error {
  background-color: #d32f2f;
  color: #fff;
}
