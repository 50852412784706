/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #4cb5b8;
  --ion-color-primary-rgb: 76 181 184;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255 255 255;
  --ion-color-primary-shade: rgb(65 170 173);
  --ion-color-primary-tint: rgb(97 191 194);

  /** secondary **/
  --ion-color-secondary: rgb(39 50 67);
  --ion-color-secondary-rgb: 39 50 67;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 255 255 255;
  --ion-color-secondary-shade: #212c3c;
  --ion-color-secondary-tint: #374355;

  /** tertiary **/
  --ion-color-tertiary: #fafafa;
  --ion-color-tertiary-rgb: 250 250 250;
  --ion-color-tertiary-contrast: #000;
  --ion-color-tertiary-contrast-rgb: 0 0 0;
  --ion-color-tertiary-shade: #dcdcdc;
  --ion-color-tertiary-tint: #fbfbfb;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16 220 96;
  --ion-color-success-contrast: #fff;
  --ion-color-success-contrast-rgb: 255 255 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #e67e22;
  --ion-color-warning-rgb: 255 206 0;
  --ion-color-warning-contrast: #fff;
  --ion-color-warning-contrast-rgb: 255 255 255;
  --ion-color-warning-shade: #ca6f1e;
  --ion-color-warning-tint: #e98b38;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245 61 61;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 255 255 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #474f5c;
  --ion-color-dark-rgb: 71 79 92;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255 255 255;
  --ion-color-dark-shade: #3e4651;
  --ion-color-dark-tint: #59616c;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152 154 162;
  --ion-color-medium-contrast: #fff;
  --ion-color-medium-contrast-rgb: 255 255 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #d3dee4;
  --ion-color-light-rgb: 211 222 228;
  --ion-color-light-contrast: #000;
  --ion-color-light-contrast-rgb: 0 0 0;
  --ion-color-light-shade: #bac3c9;
  --ion-color-light-tint: #d7e1e7;

  /** custom **/

  /** accept **/
  --ion-color-accept: #7dd871;
  --ion-color-accept-rgb: 125 216 113;
  --ion-color-accept-contrast: #000;
  --ion-color-accept-contrast-rgb: 0 0 0;
  --ion-color-accept-shade: #6ebe63;
  --ion-color-accept-tint: #8adc7f;

  /** darker **/
  --ion-color-darker: #273243;
  --ion-color-darker-rgb: 39 50 67;
  --ion-color-darker-contrast: #fff;
  --ion-color-darker-contrast-rgb: 255 255 255;
  --ion-color-darker-shade: #222c3b;
  --ion-color-darker-tint: #3d4756;

  /** lighter **/
  --ion-color-primary-lighter: #abe8eb;
  --ion-color-primary-lighter-rgb: 171 232 235;
  --ion-color-primary-lighter-contrast: #000;
  --ion-color-primary-lighter-contrast-rgb: 0 0 0;
  --ion-color-primary-lighter-shade: #96cccf;
  --ion-color-primary-lighter-tint: #b3eaed;

  /** primary-darker **/
  --ion-color-primary-darker: #357e80;
  --ion-color-primary-darker-rgb: 53 126 128;
  --ion-color-primary-darker-contrast: #fff;
  --ion-color-primary-darker-contrast-rgb: 255 255 255;
  --ion-color-primary-darker-shade: #2f6f71;
  --ion-color-primary-darker-tint: #498b8d;

  /** lightgrey **/
  --ion-color-lightgrey-bg: #f7f7f7;
  --ion-color-lightgrey-bg-rgb: 247 247 247;
  --ion-color-lightgrey-bg-contrast: #000;
  --ion-color-lightgrey-bg-contrast-rgb: 0 0 0;
  --ion-color-lightgrey-bg-shade: #d9d9d9;
  --ion-color-lightgrey-bg-tint: #f8f8f8;

  /** lightgrey border **/
  --ion-color-lightgrey-border: #ededed;
  --ion-color-lightgrey-border-rgb: 237 237 237;
  --ion-color-lightgrey-border-contrast: #000;
  --ion-color-lightgrey-border-contrast-rgb: 0 0 0;
  --ion-color-lightgrey-border-shade: #d1d1d1;
  --ion-color-lightgrey-border-tint: #efefef;

  /** lightgrey card **/
  --ion-color-lightgrey-card: #efefef;
  --ion-color-lightgrey-card-rgb: 239 239 239;
  --ion-color-lightgrey-card-contrast: #000;
  --ion-color-lightgrey-card-contrast-rgb: 0 0 0;
  --ion-color-lightgrey-card-shade: #d2d2d2;
  --ion-color-lightgrey-card-tint: #f1f1f1;

  /** yellow **/
  --ion-color-yellow: #fedc52;
  --ion-color-yellow-rgb: 254 220 82;
  --ion-color-yellow-contrast: #000;
  --ion-color-yellow-contrast-rgb: 0 0 0;
  --ion-color-yellow-shade: #e0c248;
  --ion-color-yellow-tint: #fee063;

  /** published **/
  --ion-color-published: #f4534e;
  --ion-color-published-rgb: 244 83 78;
  --ion-color-published-contrast: #000;
  --ion-color-published-contrast-rgb: 0 0 0;
  --ion-color-published-shade: #d74945;
  --ion-color-published-tint: #f56460;

  /** taken **/
  --ion-color-taken: #f79749;
  --ion-color-taken-rgb: 247 151 73;
  --ion-color-taken-contrast: #000;
  --ion-color-taken-contrast-rgb: 0 0 0;
  --ion-color-taken-shade: #d98540;
  --ion-color-taken-tint: #f8a15b;

  /** arrived **/
  --ion-color-arrived: #fedc52;
  --ion-color-arrived-rgb: 254 220 82;
  --ion-color-arrived-contrast: #000;
  --ion-color-arrived-contrast-rgb: 0 0 0;
  --ion-color-arrived-shade: #e0c248;
  --ion-color-arrived-tint: #fee063;

  /** grounded **/
  --ion-color-grounded: #5145cd;
  --ion-color-grounded-rgb: 81 69 205;
  --ion-color-grounded-contrast: #fff;
  --ion-color-grounded-contrast-rgb: 255 255 255;
  --ion-color-grounded-shade: #473db4;
  --ion-color-grounded-tint: #6258d2;

  /** finished **/
  --ion-color-finished: #7dd871;
  --ion-color-finished-rgb: 125 216 113;
  --ion-color-finished-contrast: #000;
  --ion-color-finished-contrast-rgb: 0 0 0;
  --ion-color-finished-shade: #6ebe63;
  --ion-color-finished-tint: #8adc7f;

  /** cancelled **/
  --ion-color-cancelled: #273243;
  --ion-color-cancelled-rgb: 39 50 67;
  --ion-color-cancelled-contrast: #fff;
  --ion-color-cancelled-contrast-rgb: 255 255 255;
  --ion-color-cancelled-shade: #222c3b;
  --ion-color-cancelled-tint: #3d4756;

  /** online **/
  --ion-color-online: #7dd871;
  --ion-color-online-rgb: 125 216 113;
  --ion-color-online-contrast: #000;
  --ion-color-online-contrast-rgb: 0 0 0;
  --ion-color-online-shade: #6ebe63;
  --ion-color-online-tint: #8adc7f;

  /** in transit **/
  --ion-color-in_transit: #f79749;
  --ion-color-in_transit-rgb: 247 151 73;
  --ion-color-in_transit-contrast: #000;
  --ion-color-in_transit-contrast-rgb: 0 0 0;
  --ion-color-in_transit-shade: #d98540;
  --ion-color-in_transit-tint: #f8a15b;

  /** cancel **/
  --ion-color-confirm_cancel: #f04141;
  --ion-color-confirm_cancel-rgb: 240 65 65;
  --ion-color-confirm_cancel-contrast: #fff;
  --ion-color-confirm_cancel-contrast-rgb: 255 255 255;
  --ion-color-confirm_cancel-shade: #d33939;
  --ion-color-confirm_cancel-tint: #f25454;

  /** hold **/
  --ion-color-hold: #b4b5b4;
  --ion-color-hold-rgb: 180 181 180;
  --ion-color-hold-contrast: #000;
  --ion-color-hold-contrast-rgb: 0 0 0;
  --ion-color-hold-shade: #9e9f9e;
  --ion-color-hold-tint: #bcbcbc;

  /** offline **/
  --ion-color-offline: #273243;
  --ion-color-offline-rgb: 39 50 67;
  --ion-color-offline-contrast: #fff;
  --ion-color-offline-contrast-rgb: 255 255 255;
  --ion-color-offline-shade: #222c3b;
  --ion-color-offline-tint: #3d4756;
}
